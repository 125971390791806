import React from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import * as bech32 from 'bech32';
import * as eip55 from 'eip55';
import { networks } from '../config';

import 'react-toastify/dist/ReactToastify.css';

import NetworkContext from '../contexts/NetworkContext';
import { AccAddress, EvmAddress } from '@xpla/xpla.js';

/// config
const addrPrefix = 'xpla'
const denomCtx = 'XPLA'
// const baseUrl = 'http://localhost:3060/tx/'
const decimal = 1000000000000000000;

const { encode, toWords } = bech32;

const hexToBech32 = (
  prefix,
  hex,
) => {
  if (typeof hex === 'string') {
    if (hex.startsWith('0x')) hex = hex.substring(2);
    hex = hex.toLowerCase();
    hex = Buffer.from(hex, 'hex');
  }
  const words = toWords(hex);
  const bech32 = encode(prefix, words);
  return bech32;
};


const validateWalletAddress = (str) => {
  try {
    // const { prefix } = bech32.decode(str);
    // if (prefix !== addrPrefix) {
    //   throw new Error('Invalid address');
    // }

    if (!AccAddress.validate(str) && !EvmAddress.validate(str)) {
      throw new Error('Invalid address');
    }
  } catch {
    return 'Enter valid wallet address';
  }
};

const sendSchema = Yup.object().shape({
  address: Yup.string().required('Required'),
  denom: Yup.string().required('Required'),
});

const DENUMS_TO_TOKEN = {
  axpla: denomCtx,
};

const REQUEST_LIMIT_SECS = 30;

class HomeComponent extends React.Component {
  static contextType = NetworkContext;
  //recaptchaRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      verified: true,
      response: '',
    };
  }

  handleCaptcha = (response) => {
    this.setState({
      response,
      verified: true,
    });
  };

  handleSubmit = (values, { resetForm }) => {
    const network = networks.filter(
      (n) => n.chainId === this.context.network
    )[0];
    // same shape as initial values
    this.setState({
      sending: true,
      //verified: false,
    });

    //this.recaptchaRef.current.reset();

    const timeout = setTimeout(() => {
      this.setState({ sending: false });
    }, REQUEST_LIMIT_SECS * 1000);

    axios
      .post(network.faucetUrl, {
        address: EvmAddress.validate(values.address) ? hexToBech32('xpla', values.address) : values.address,
        denom: values.denom,
        response: this.state.response, 
        chainid: this.context.network,            
      })
      .then((res) => {
        const response = res.data.response['tx_response'] || res.data.response;
        console.log(response);
        const getCoin = Number(response.amount) / decimal;
        console.log(getCoin)

        if (response.code) {
          toast.error(`Error: ${response.raw_log || `code: ${response.code}`}`);
        } else {
          const url = network.explorerUrl + '/testnet/tx/' + response.txhash;
          //const url = `http://localhost:3060/tx/${response.txhash}`;
          //const url = `https://finder.terra.money/testnet/tx/${response.txhash}`;
          //const url = `http://34.64.49.234/mainnet/tx/${response.txhash}`;
          toast.success(
            <div>
              <p>
                Successfully Sent {getCoin}{' '}
                {DENUMS_TO_TOKEN[values.denom]} to {values.address}
              </p>
              <a href={url} target="_blank" rel="noopener noreferrer">
                Go to explorer
              </a>
            </div>
          );
        }

        resetForm();
        this.setState({
          sending: false,
          //verified: false,
        });
        clearTimeout(timeout);
      })
      .catch((err) => {
        let errText = err.message;

        if (err.response) {
          if (err.response.data) {
            errText = err.response.data;
          } else {
            switch (err.response.status) {
              case 400:
                errText = 'Invalid request';
                break;
              case 403:
              case 429:
                errText = 'Too many requests';
                break;
              case 404:
                errText = 'Cannot connect to server';
                break;
              case 500:
              case 502:
              case 503:
                errText = 'Faucet service temporary unavailable';
                break;
              default:
                errText = err.message;
            }
          }
        }

        toast.error(`An error occurred: ${errText}`);

        resetForm();
        this.setState({
          sending: false,
          //verified: false,
        });
        clearTimeout(timeout);
      });
  };

  render() {
    return (
      <>
        <h1 className="page_title">XPLA Faucet</h1>
        <article className="card">
          <header className="card_header">
            <p>
              Dear Pilot! Here you can receive XPLA tokens for testing purposes.<br/>
              Please note that there are only a limited number of tokens available to use on the test net.
            </p>
          </header>
          <Formik
            initialValues={{
              address: '',
              denom: denomCtx,
            }}
            validationSchema={sendSchema}
            onSubmit={this.handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className="form">
                <div className="input_container">
                  <label htmlFor="address" className="form_label">Testnet Address</label>
                  <Field
                    name="address"
                    placeholder="Address"
                    validate={validateWalletAddress}
                    className="input"
                  />
                  {errors.address && touched.address ? (
                    <span className="form_message">{errors.address}</span>
                  ) : null}
                </div>
                <Field type="hidden" name="denom" value={denomCtx} />
                <div className="button_container">
                  <button
                    className='button primary'
                    disabled={/*!this.state.verified ||*/ this.state.sending}
                    type="submit"
                  >
                    {/* <i className="material-symbols-rounded">
                      check
                    </i> */}
                    <span>
                      {this.state.sending
                        ? 'Waiting for next tap'
                        : 'Send me tokens'}
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </article>
        
        <article className="card">
          <header className="card_header">
            <h1 className="card_title">Don't have a test-net address?</h1>
            <p className="card_description">Create your test-net address using the crypto wallet for XPLA, XPLA - wallet!</p>
          </header>
          <a href="https://docs.xpla.io" className="button default" target="_blank" rel="noreferrer">Join the latest testnet</a>
        </article>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          pauseOnHover
        />
      </>
    );
  }
}

export default HomeComponent;
