import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SelectNetworks from './components/SelectNetworks';
import Home from './components/Home';
import XplaFaucetLogo from './assets/XplaFaucetLogo.svg'

class App extends Component {
  showCurrentYear() {
    return new Date().getFullYear();
  }
  render() {
    return (
      <Router>
        <div className="wrap">
          <header className="header">
            <div className="headerWrap">
              <a href="/" className="logo"><img src={XplaFaucetLogo} alt="" /></a>
              <div className="select_container">
                <SelectNetworks className="network_select" />
              </div>
            </div>
          </header>
          <main className="content">
            <div className="content_wrap">
              <Route exact path="/" component={Home} />
            </div>
          </main>
        </div>
      </Router>
    );
  }
}

export default App;
