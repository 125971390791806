import React, { useContext } from 'react';

import { networks } from '../config';
import NetworkContext from '../contexts/NetworkContext';

const SelectNetworks = (props) => {
  const { network, setNetwork } = useContext(NetworkContext);

  return (
    <div className={props.className}>
      <select
        className="select"
        value={network}
        onChange={(e) => setNetwork(e.target.value)}
      >
        {networks.map(({ chainId }, index) => (
          <option key={index}>{chainId}</option>
        ))}
      </select>
      <i className="material-symbols-rounded">
        arrow_drop_down
      </i>
    </div>
  );
};

export default SelectNetworks;
